<template>
  <div class="account-informate">
    <h1>{{ $t("change_account_info") }}</h1>
    <b-row>
      <b-col md="6">
        <b-form-group
          class="account-inputs"
          id="first-name-group"
          :label="$t('form_first_name') + '*'"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            v-model="form.firstname"
            type="text"
            v-on:blur="validateFirstname()"
            :state="form.firstnameState"
            aria-describedby="firstname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="firstname-feedback">
            {{ form.firstnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group
          class="account-inputs"
          id="last-name-group"
          :label="$t('form_last_name') + '*'"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            v-model="form.lastname"
            type="text"
            v-on:blur="validateLastname()"
            :state="form.lastnameState"
            aria-describedby="lastname-feedback"
          ></b-form-input>
          <b-form-invalid-feedback id="lastname-feedback">
            {{ form.lastnameError }}
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="form-footer">
      <b-button
        @click="submit"
        variant="info"
        class="text-white form-submit-btn"
        >Opslaan</b-button
      >
    </div>
  </div>
</template>

<script>
//import passwordMeter from "vue-simple-password-meter";

export default {
  name: "AccountInformate",
  components: {
    //passwordMeter,
  },
  data() {
    return {
      form: {
        firstname: "",
        firstnameState: null,
        firstnameError: "",
        lastname: "",
        lastnameState: null,
        lastnameError: "",
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
  },
  watch: {
    user() {
      this.form.firstname = this.user.firstname;
      this.form.lastname = this.user.lastname;
    },
  },
  mounted() {
    this.form.firstname = this.user.firstname;
    this.form.lastname = this.user.lastname;
  },
  methods: {
    async submit() {
      let formValid = true;
      if (this.form.firstnameState != true) {
        this.validateFirstname();
        if (this.firstnameState != true) {
          formValid = false;
        }
      }
      if (this.form.lastnameState != true) {
        this.validateLastname();
        if (this.lastnameState != true) {
          formValid = false;
        }
      }
      if (formValid == true) {
        const retval = await this.$store.dispatch("user/updateAccount", {
          account: this.form,
        });
        if (retval == true) {
          const msg = {
            type: "success",
            title: this.$t("account_modify"),
            text: this.$t("account_modified"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
        }
      }
    },
    validateFirstname() {
      if (this.form.firstname == "") {
        this.form.firstnameState = false;
        this.form.firstnameError = this.$t("firstname_required");
      } else {
        this.form.firstnameState = true;
      }
    },
    validateLastname() {
      if (this.form.lastname == "") {
        this.form.lastnameState = false;
        this.form.lastnameError = this.$t("lastname_required");
      } else {
        this.form.lastnameState = true;
      }
    },
  },
};
</script>
