import galleryImage1 from '@/base/assets/productPageGalleryImg01.jpg'
import galleryImage2 from '@/base/assets/productPageGalleryImg02.jpg'
import galleryImage3 from '@/base/assets/productPageGalleryImg03.jpg'
import galleryImage4 from '@/base/assets/productPageGalleryImg04.jpg'

const wishListAdapter = {
  dropdownItems(wishListItems) {
    return wishListItems.map((el, i) => ({
      dropItemId: i + 1,
      dropItemImg: el.imgSrc,
      dropItemName: el.name,
      dropItemPrice: el.price,
      id: el.id,
    }))
  },

  productItem(wishItem) {
    return {
      ...wishItem,
      galleryImages: [
        wishItem.imgSrc,
        galleryImage1,
        galleryImage2,
        galleryImage3,
        galleryImage4,
      ],
      parameters: {
        normalPrice: wishItem.price,
        currentPrice: '€97,99',
        discount: wishItem.sale,
        discountComment: wishItem.saleComment,
        availableColors: [
          { name: 'Wit', selected: false },
          { name: 'Rood', selected: true },
          { name: 'Zwart', selected: false },
        ],
        selectorOptions: [
          { option: 'S', price: '€97,99' },
          { option: 'M', price: '€97,99' },
          { option: 'L', price: '€97,99' },
          { option: 'XL', price: '€97,99' },
        ],
        sizesOptions: wishItem.availableSizes
          ? wishItem.availableSizes.map(size => ({
              name: size,
              selected: false,
            }))
          : [ 
          { "name": "XS", "selected": false }, 
          { "name": "S", "selected": false }, 
          { "name": "M", "selected": false }, 
          { "name": "L", "selected": false }, 
          { "name": "XL", "selected": false }, 
          { "name": "3XL", "selected": false } 
        ],
        multiParams: wishItem.multiParams
      },
    }
  },
}

export default wishListAdapter
