<template>
  <div class="wishlist">
    Wensenlijst
    <div class="row">
      <div
        class="col-6 col-md-4 mb-md-45"
        v-for="product of wishlistItems"
        :key="product.id"
      >
        <ProductCardWishlist :product="product" />
      </div>
    </div>
  </div>
</template>

<script>
import Cart from "@storefront/core/modules/cart/mixins";
import ProductCardWishlist from "@/base/core/components/account/ProductCardWishlist";
export default {
  name: "AccountWishlist",
  mixins: [Cart],
  components: { ProductCardWishlist },
  methods: {
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
  },
};
</script>
