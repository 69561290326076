<template>
  <div class="product-card--holder">
    <a
      href="#"
      class="lnr lnr-cross add-to-wishlist"
      @click.prevent="removeItem(dropItem.id)"
    ></a>
    <b-link :to="`/${product.product.url_key}`" class="product-card">
      <div v-if="product.product.image.large != null">
        <VueLazyBackgroundImage
          :image-source="product.product.image.large"
          image-class="product-card--img-top"
          errorImage="@/base/assets/logo.jpg"
          loadingImage="@/base/assets/logo.jpg"
          style="background-size: contain"
        >
          <div class="actionbuttons">
            <span class="sale-txt" v-if="onSale == true">sale</span>
            <span class="sale-txt" v-if="onNew == true">new</span>
          </div>
        </VueLazyBackgroundImage>
      </div>
      <div class="product-card--product-name">{{ product.product.name }}</div>

      <span class="d-block product-card--price"
        >&euro;{{
          product.product.price_range.minimum_price.final_price.value
            .toFixed(2)
            .replace(".", ",")
        }}</span
      >
    </b-link>
  </div>
</template>

<script>
import VueLazyBackgroundImage from "@/base/core/components/core/VueLazyBackgroundImage";

export default {
  name: "ProductCardWishlist",
  props: {
    product: {
      type: Object,
      require: true,
    },
  },
  components: {
    VueLazyBackgroundImage,
  },
  computed: {
    onSale() {
      return false;
    },
    onNew() {
      return false;
    },
  },
  methods: {
    removeItem(id) {
      this.$store.dispatch("user/removeProductWishlist", {
        id: id,
      });
    },
  },
};
</script>
