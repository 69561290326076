<template>
  <div class="create-account-thanks">
    <h2>Bedankt voor uw bestelling!</h2>
    <p>
      Uw bestelling komt uw kant op. Hieronder vind u een overzicht van uw
      bestelling.
    </p>
    <b-row class="content">
      <b-col md="6" class="d-flex">
        <div class="selected-image-holder">
          <b-link href="#" @click.stop="UpdateIsInWishList">
            <i class="lnr lnr-heart"></i>
            <div class="heart-icon" v-if="isInWishList">
              <img src="@/base/assets/heart.png" alt="heart" />
            </div>
          </b-link>
          <div class="selected-image">
            <img src="@/base/assets/productPageGalleryImg01.jpg" alt="heart" />
          </div>
        </div>
        <div class="text">
          <h4>Rogelli fietsjack winter Trani 3.0 Zwart/Rood</h4>
          <dl class="d-list">
            <dt>Prijs per stuk:</dt>
            <dd>€97,99</dd>
            <dt>Aantal:</dt>
            <dd>1</dd>
            <dt>Kleur:</dt>
            <dd>Rood</dd>
            <dt>Maat:</dt>
            <dd>L</dd>
          </dl>
        </div>
      </b-col>
      <b-col md="6">
        <h4>Bestelgegevens</h4>
        <dl class="d-list list-custom">
          <dt>Bestelstatus:</dt>
          <dd>Klaar voor verzending</dd>
          <dt>Bestelnummer:</dt>
          <dd>1121438509</dd>
          <dt>Besteldatum:</dt>
          <dd>23-08-20 10:12</dd>
        </dl>
        <b-row cols="2">
          <b-col sm="4">
            <span class="label-address">Factuuradres:</span>
            <address>
              <span>Jan Janssen</span>
              <span>Grotestraat 10 </span>
              <span>1234 AB, AMSTERDAM</span>
              <span>NL</span>
            </address>
          </b-col>
          <b-col sm="4">
            <span class="label-address">Leveradres:</span>
            <address>
              <span>Jan Janssen</span>
              <span>Grotestraat 10 </span>
              <span>1234 AB, AMSTERDAM</span>
              <span>NL</span>
            </address>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import wishListAdapter from "@/base/helpers/adapters/wishListAdapter";

export default {
  name: "CreateAccountThanks",

  components: {},
  data() {
    return {
      isInWishList: true,
    };
  },
  computed: {
    ...mapGetters(["getProduct", "getAllProducts"]),
    product() {
      const product = this.getProduct(this.$route.params.id);
      return wishListAdapter.productItem(product || this.getAllProducts[0]);
    },
  },
  methods: {
    ...mapActions(["setProductStatus"]),
    UpdateIsInWishList() {
      this.setProductStatus({ productId: this.product.id });
      this.isInWishList = !this.isInWishList;
    },
  },
};
</script>


