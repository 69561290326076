<template>
  <div class="account-newsletter">
    <div>
      <div class="account-address-book">
        <h1>{{ $t("newsletter_subscriptions") }}</h1>
        <div class="address-block">
          <h2>{{ $t("newsletter_subscription_option") }}</h2>
          <b-row>
            <b-col sm="12">
              <b-form-checkbox
                id="checkbox-1"
                name="checkbox-1"
                label-for="checkbox1"
                v-model="is_subscribed"
                >{{ $t("newsletter_subscription_selected") }}</b-form-checkbox
              >
            </b-col>
          </b-row>
        </div>

        <div class="form-footer">
          <b-button
            @click="save"
            variant="info"
            class="text-white form-submit-btn"
            >{{ $t("save") }}</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsletterSubscription",
  components: {},
  mounted() {
    this.is_subscribed = this.user.is_subscribed;
  },
  data() {
    return {
      is_subscribed: false,
    };
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
  },
  methods: {
    save() {
      this.$store.dispatch("user/updateUserNewsletter", {
        is_subscribed: this.is_subscribed,
      });
    },
  },
};
</script>
