<template>
  <div class="account-my-account">
    <h1>{{ $t("my_account") }}</h1>
    <div class="address-block">
      <h2>{{ $t("account_information") }}</h2>
      <b-row>
        <b-col sm="6">
          <h3>{{ $t("contact_information") }}</h3>
          <address>
            <span>{{ user.firstname }} {{ user.lastnamename }}</span>
            <span>{{ user.email }}</span>
          </address>
          <b-link class="link" @click="goToEditAccount">{{ $t("edit") }}</b-link
          >&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<b-link
            class="link"
            @click="goToEditPassword"
            >{{ $t("change_password") }}</b-link
          >
        </b-col>
        <b-col sm="6">
          <h3>{{ $t("newsletters") }}</h3>
          <b-link class="link" @click="goToEditSubscription">{{
            $t("modify_newsletters")
          }}</b-link>
        </b-col>
      </b-row>
    </div>
    <div class="address-block">
      <h2>
        {{ $t("address_book") }} &nbsp;&nbsp;&nbsp;<b-link
          class="link"
          @click="goToManageAddress"
          >{{ $t("manage_addresses") }}</b-link
        >
      </h2>
      <b-row>
        <b-col sm="6">
          <h3>{{ $t("default_billing_address") }}</h3>
          <div v-if="defaultBilling == null">
            {{ $t("no_default_billing_address") }}
          </div>
          <address v-if="defaultBilling != null">
            <span
              >{{ defaultBilling.firstname }}
              {{ defaultBilling.lastname }}</span
            >
            <span v-if="defaultBilling.company != null">{{
              defaultBilling.company
            }}</span>
            <span>{{ defaultBilling.street[0] }}</span>
            <span
              >{{ defaultBilling.city }}, {{ defaultBilling.postcode }}</span
            >
            <span>{{ defaultBilling.country_code }}</span>
            <span
              >T:<a :href="`tel:` + defaultBilling.telephone">
                {{ defaultBilling.telephone }}</a
              ></span
            >
            <span v-if="defaultBilling.vat_id != null"
              >VAT:{{ defaultBilling.vat_id }}</span
            >
          </address>
          <b-link class="link" @click="goToEditAddress(defaultBilling.id)">{{
            $t("edit_address")
          }}</b-link>
        </b-col>
        <b-col sm="6">
          <h3>{{ $t("default_shipping_address") }}</h3>
          <div v-if="defaultShipping == null">
            {{ $t("no_default_shipping_address") }}
          </div>
          <address v-if="defaultShipping != null">
            <span
              >{{ defaultShipping.firstname }}
              {{ defaultShipping.lastname }}</span
            >
            <span v-if="defaultShipping.company != null">{{
              defaultShipping.company
            }}</span>
            <span>{{ defaultShipping.street[0] }}</span>
            <span
              >{{ defaultShipping.city }}, {{ defaultShipping.postcode }}</span
            >
            <span>{{ defaultShipping.country_code }}</span>
            <span
              >T:<a :href="`tel:` + defaultShipping.telephone">
                {{ defaultShipping.telephone }}</a
              ></span
            >
            <span v-if="defaultShipping.vat_id != null"
              >VAT:{{ defaultShipping.vat_id }}</span
            >
          </address>
          <b-link class="link" @click="goToEditAddress(defaultShipping.id)">{{
            $t("edit_address")
          }}</b-link>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "AccountMyAccount",
  components: {},

  data() {
    return {};
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    defaultBilling() {
      return this.$store.getters["user/getDefaultBillingAddress"];
    },
    defaultShipping() {
      return this.$store.getters["user/getDefaultShippingAddress"];
    },
  },
  methods: {
    goToEditAccount() {
      this.tabIndex = 4;
    },
    goToEditPassword() {
      this.tabIndex = 6;
    },
    goToEditAddress(id) {
      this.tabIndex = 3;
      this.$store.commit("user/setEditAddressId", id);
    },
    goToManageAddress() {
      this.tabIndex = 3;
    },
    goToEditSubscription() {
      this.tabIndex = 7;
    },
  },
};
</script>

