<template>
  <div class="account-orders">
    <NotifyModal
      :show="showNotifyModal"
      :product-sku="notifyModalProduct && notifyModalProduct.sku"
      @close="closeNotifyModal"
    />
    <b-modal
      id="modal"
      v-model="outOfStockProductsModal"
      size="md"
      content-class="no-product-stock"
      title="Product(en) die niet op voorraad zijn"
      centered
      @hide="
        outOfStockProductsList = [];
        outOfStockProductsModal = false;
      "
      :hide-footer="true"
      ><div class="no-product-wrap">
        <div
          class="no-product-box"
          v-for="product in outOfStockProductsList"
          :key="product.product_sku"
        >
          <img :src="product.image.medium" />
          <div class="info-popup">
            <h2>{{ product.product_name }}</h2>
            <p class="my-price">
              {{ formatCurrency(product.product_sale_price.value) }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
    <div
      class="address-block"
      v-if="showOrders"
      :class="{ 'hide-tab-content': isTabShow && isMobileScreen }"
    >
      <h1>{{ $t("my_orders") }}</h1>
      <div class="back-link" v-if="isMobileScreen" @click="$emit('showTabs')">
        <b-icon icon="chevron-left"></b-icon>
        <span>{{ $t("go_back") }}</span>
      </div>

      <div class="address-table-wrap">
        <h3 v-if="ordersList && ordersList.length === 0">
          {{ $t("no_orders_available") }}
        </h3>
        <template v-if="ordersList && ordersList.length > 0">
          <b-table
            id="order-table"
            class="address-table"
            responsive
            :items="ordersList"
            :fields="fields"
            :per-page="paginationConf.perPage"
            :current-page="paginationConf.currentPageNr"
            v-if="!isMobileScreen"
          >
            <template v-slot:cell(action)="data">
              <div
                class="order-btn-action"
                :class="{ 'only-view': !allowedReorder }"
              >
                <b-link
                  class="c-btn c-btn-primary"
                  @click="viewOrder(data.item)"
                  >{{ $t("view_order") }}</b-link
                ><span v-if="allowedReorder">&nbsp;|&nbsp;</span>
                <b-link
                  v-if="allowedReorder"
                  class="c-btn c-btn-green"
                  @click="orderAgain(data.item)"
                >
                  {{ $t("order_again") }}</b-link
                >
              </div>
            </template>
          </b-table>
          <div class="mobile-order" v-if="isMobileScreen">
            <div class="mo-inner">
              <div class="mo-title">
                <label>{{ $t("order_detail") }}</label>
                <label>{{ $t("action") }}</label>
              </div>
              <div class="mo-info">
                <ul>
                  <li
                    v-for="product in ordersList"
                    :key="product.product_sku"
                    :per-page="paginationConf.perPage"
                    :current-page="paginationConf.currentPageNr"
                  >
                    <div class="text">
                      <label>{{ product.number }}</label>
                      <p>{{ convertDate(product.order_date) }}</p>
                      <p>
                        {{
                          product.billing_address &&
                          product.billing_address.firstname +
                            " " +
                            product.billing_address.lastname
                        }}
                      </p>
                      <p>
                        {{
                          formatCurrency(product.total.base_grand_total.value)
                        }}
                      </p>
                      <span>{{ product.status }}</span>
                    </div>
                    <div class="action">
                      <b-link
                        class="c-btn c-btn-primary"
                        @click="viewOrder(product)"
                        >{{ $t("view_order") }}</b-link
                      >
                      <b-link
                        v-if="allowedReorder"
                        class="c-btn c-btn-green"
                        @click="orderAgain(product)"
                        >{{ $t("order_again") }}</b-link
                      >
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="cst-pagination-bar">
            <label>
              Pagina
              <span>{{ paginationConf.currentPageNr }}</span> van
              <span>{{ Math.ceil(rows / paginationConf.perPage) }}</span>
            </label>
            <b-pagination
              v-model="paginationConf.currentPageNr"
              :total-rows="rows"
              :per-page="paginationConf.perPage"
              class="cst-pagination"
              aria-controls="order-table"
              @change="tmp"
            ></b-pagination>
          </div>

          <div class="pagination-bar-down">
            <div class="left">
              <div class="display-drop">
                <label>Weergave:</label>
                <select v-model.number="paginationConf.perPage">
                  <option value="1">1</option>
                  <option value="9">9</option>
                  <option value="24">24</option>
                  <option value="48">48</option>
                  <option value="72">72</option>
                  <option value="98">98</option>
                  <option value="120">120</option>
                </select>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="order-view" v-if="!showOrders && orderDetail">
      <div class="title">
        <h2>{{ $t("order") + " " + orderDetail.number }}</h2>
        <p>{{ $t("order_detail") }}</p>
      </div>
      <div class="order-view-inner">
        <div class="order-view-wrap">
          <div
            class="single-product"
            v-for="product in orderDetail.items"
            :key="product.product_sku"
          >
            <div class="img-wrap">
              <div
                class="img"
                :style="`background-image: url('${product.image.medium}');`"
              ></div>
            </div>
            <div class="product-info">
              <div class="product-name">{{ product.product_name }}</div>
              <div class="extra-info">
                <div>{{ $t("number") }}: {{ product.quantity_ordered }}</div>
              </div>
              <div class="stock-info">
                <label
                  v-if="product.product"
                  class="stock-status status"
                  :class="
                    product.product.stock_status == 'IN_STOCK'
                      ? 'in-stock'
                      : 'out-stock'
                  "
                >
                  {{
                    $t(
                      product.product.stock_status == "IN_STOCK"
                        ? "In Stock"
                        : "Out of Stock"
                    )
                  }}
                </label>
                <div class="price">
                  {{ formatCurrency(product.product_sale_price.value) }}
                </div>
              </div>

              <div v-if="allowedReorder">
                <div
                  v-if="
                    product.product &&
                    product.product.stock_status == 'OUT_OF_STOCK'
                  "
                  class="cart-btn notify-order"
                  @click="addToNotify(product.product)"
                  key="notify-btn"
                >
                  <i class="fas fa-envelope"></i>
                </div>

                <div
                  v-else
                  class="order-again-btn"
                  @click="orderProductAgain(product)"
                >
                  <b-link class="c-btn c-btn-green">
                    {{ $t("order_again") }}
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="common-part">
          <div class="address">
            <div class="top">
              <div class="date">
                {{ $t("order_date") }}:
                <span>{{ convertDate(orderDetail.order_date) }}</span>
              </div>
              <div class="status">
                {{ $t("status") }}:
                <span>{{ orderDetail.status }}</span>
              </div>
            </div>
            <div class="address-info" v-if="orderDetail.billing_address">
              <div>
                {{ orderDetail.billing_address.firstname }}
                {{ orderDetail.billing_address.lastname }}
              </div>
              <div>{{ orderDetail.billing_address.street[0] }}</div>
              <div>
                {{ orderDetail.billing_address.postcode }}
                {{ orderDetail.billing_address.city }}
              </div>
              <div class="mo-num">
                {{ orderDetail.billing_address.telephone }}
              </div>
            </div>
          </div>
          <div class="grand-total">
            <ul>
              <li>
                <label>{{ $t("total_shipping_cart") }}:</label>
                <div>
                  <p>{{ formatCurrency(orderDetail.total.subtotal.value) }}</p>
                </div>
              </li>
              <li>
                <label>{{ $t("postage_cost") }}:</label>
                <div>
                  <p>
                    {{ formatCurrency(orderDetail.total.total_shipping.value) }}
                  </p>
                </div>
              </li>
              <li>
                <label>{{ $t("VAT") }}:</label>
                <div>
                  <p>{{ formatCurrency(orderDetail.total.total_tax.value) }}</p>
                </div>
              </li>
            </ul>
            <div class="final-price">
              <div class="final-total">
                <label>{{ $t("total_btw") }}:</label>
                <div>
                  <p>
                    {{
                      formatCurrency(orderDetail.total.base_grand_total.value)
                    }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-link @click="viewOrderList" class="back-link">
          <b-icon icon="chevron-left"></b-icon>
          {{ $t("back") }}
        </b-link>
      </div>
    </div>
  </div>
</template>

<script>
import config from "@config";
import axios from "axios";
import store from "@/store";
import { Logger } from "@storefront/core/lib/logger";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { isMobile } from "mobile-device-detect";
import NotifyModal from "@/base/core/components/common/NotifyModal.vue";

export default {
  name: "AccountOrders",
  props: {
    isTabShow: {
      type: Boolean,
    },
  },
  components: {
    NotifyModal,
  },
  data() {
    return {
      showOrders: true,
      orderDetail: null,
      showNotifyModal: false,
      notifyModalProduct: null,
      outOfStockProductsModal: false,
      outOfStockProductsList: [],
      paginationConf: {
        currentPageNr: 1,
        perPage: 9,
      },
      options: [10, 20, 30],
      fields: [
        {
          key: "number",
          label: this.$t("order_number"),
        },
        {
          key: "order_date",
          label: this.$t("order_date"),
          formatter: (value) => this.convertDate(value),
        },
        {
          key: "billing_address",
          label: this.$t("send_to"),
          formatter: (value) => value.firstname + " " + value.lastname,
        },
        {
          key: "price",
          label: this.$t("grand_total"),
          formatter: (value, key, item) => {
            return this.formatCurrency(item.total.base_grand_total.value);
          },
        },
        {
          key: "status",
          label: this.$t("status"),
        },
        {
          key: "action",
          label: this.$t("action"),
        },
      ],
    };
  },
  created() {
    this.$store.dispatch("user/loadOrders", { page: 1, perPage: 10 });
  },
  computed: {
    rows() {
      return this.ordersList.length;
    },
    allowedReorder() {
      return config.allow_reorder;
    },
    ordersList() {
      const orders = this.$store.getters["user/getMyOrders"];
      orders.forEach((element, index) => {
        orders[index].status = this.$t(element.status);
      });
      orders.sort((a, b) => {
        if (a.order_date > b.order_date) {
          return -1;
        } else {
          return 1;
        }
      });
      return orders;
    },
    isMobileScreen() {
      return isMobile;
    },
    cartItems() {
      return this.$store.getters["cart/getCartItems"];
    },
    activeTab() {
      return this.$store.getters["user/getAccountTabIndex"];
    },
  },
  methods: {
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    convertDate(date) {
      let reviewDate = new Date(date);
      let dd = reviewDate.getDate();
      let mm = reviewDate.getMonth() + 1;
      let yyyy = reviewDate.getFullYear();
      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;
      reviewDate = dd + "-" + mm + "-" + yyyy;
      return reviewDate;
    },
    tmp(id) {
      this.showOrders = false;
      setTimeout(() => {
        this.showOrders = true;
        this.paginationConf.currentPageNr = id;
      }, 0.001);
    },
    viewOrder(data) {
      this.showOrders = false;
      this.orderDetail = data;
    },
    viewOrderList() {
      this.showOrders = true;
      this.orderDetail = null;
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    addToNotify(product) {
      this.notifyModalProduct = product;
      this.showNotifyModal = true;
    },
    closeNotifyModal() {
      this.notifyModalProduct = null;
      this.showNotifyModal = false;
    },
    async orderProductAgain(product) {
      if (product.product) {
        if (!product.product.backorders_allowed) {
          if (typeof product.product.stock_qty != "undefined") {
            let quantity = 1;
            const existingProduct = this.cartItems.find(
              (item) => product.product_sku == item.product.sku
            );
            if (existingProduct) {
              quantity += existingProduct.quantity;
            }
            if (quantity > product.product.stock_qty) {
              const msg = {
                type: "danger",
                title: this.$t("shopping_basket"),
                text: this.$t("product_not_in_stock_msg", {
                  numberOf: product.product.stock_qty,
                }),
              };
              this.$store.dispatch("messages/sendMessage", { message: msg });
              return false;
            }
          }
        }
      }

      if (product.product_type === "simple") {
        const items =
          '[ { sku: "' + product.product_sku + '" quantity: ' + 1 + " } ]";

        this.addToCart(items);
      } else if (product.product_type === "bundle") {
        if (product.bundle_options != null) {
          const selected_options = [];
          product.bundle_options.forEach((element) => {
            selected_options.push(element.values[0].uid);
          });

          let items =
            "[{ quantity: " +
            1 +
            ' sku: "' +
            product.product_sku +
            '" selected_options: ' +
            JSON.stringify(selected_options) +
            " }]";

          this.addToCart(items);
        } else {
          this.$router.push(product.product_url_key);
        }
      } else {
        this.$router.push(product.product_url_key);
      }
    },
    async orderAgain(order) {
      const lang = getCurrentLanguage();
      const storelang = config.languages[lang];
      const storeview = storelang["storeview"];

      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + store.getters["user/getUserToken"],
        Store: storeview,
      };

      const query =
        'mutation{ reorderItems(orderNumber: "' +
        order.number +
        '"){ ' +
        "cart { id items { uid product { sku } quantity prices { price { value } } } } " +
        "userInputErrors{ code message path } } }";

      const retval = await axios({
        url: config.shop.graphQLURL,
        method: "POST",
        headers: headers,
        data: { query: query },
      }).catch((e) => {
        Logger.error("reOrder", "Account orders", e)();
        throw e;
      });

      if (retval.data.data.reorderItems != null) {
        if (retval.data.data.reorderItems.userInputErrors.length === 0) {
          const msg = {
            type: "success",
            title: this.$t("shopping_basket"),
            text: this.$t("added_product"),
          };
          this.$store.dispatch("messages/sendMessage", { message: msg });
          await this.$store.dispatch("cart/loadCart");
          // this.$router.push("/checkout/cart")
        } else {
          const errors = retval.data.data.reorderItems.userInputErrors;
          errors.forEach((element) => {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: element.message,
            };
            store.dispatch("messages/sendMessage", { message: msg });
          });
          this.$store.dispatch("cart/loadCart");
        }
      }
    },

    async addToCart(items) {
      let retval = await this.$store.dispatch("cart/addProductToCart", {
        items,
      });

      if (retval == false) {
        const msg = {
          type: "danger",
          title: this.$t("shopping_basket"),
          text: this.$t("not_add_product"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      } else {
        const msg = {
          type: "success",
          title: this.$t("shopping_basket"),
          text: this.$t("added_product"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }

      this.$store.dispatch("cart/loadCart");
    },
  },
  watch: {
    activeTab() {
      this.showOrders = true;
    },
  },
};
</script>
