<template>
  <div>
    <div v-if="editAddressId == 0">
      <div class="account-address-book">
        <h1>{{ $t("address_book") }}</h1>
        <div class="address-block">
          <h2>{{ $t("default_addresses") }}</h2>
          <b-row>
            <b-col sm="6">
              <h3>{{ $t("default_billing_address") }}</h3>
              <div v-if="defaultBilling == null">
                {{ $t("no_default_billing_address") }}
              </div>
              <address v-if="defaultBilling != null">
                <span
                  >{{ defaultBilling.firstname }}
                  {{ defaultBilling.lastname }}</span
                >
                <span v-if="defaultBilling.company != null">{{
                  defaultBilling.company
                }}</span>
                <span>{{ defaultBilling.street[0] }}</span>
                <span
                  >{{ defaultBilling.city }},
                  {{ defaultBilling.postcode }}</span
                >
                <span>{{ defaultBilling.country_code }}</span>
                <span
                  >T:<a :href="`tel:` + defaultBilling.telephone">
                    {{ defaultBilling.telephone }}</a
                  ></span
                >
                <span v-if="defaultBilling.vat_id != null"
                  >VAT:{{ defaultBilling.vat_id }}</span
                >
              </address>
              <b-link
                class="link"
                @click="goToEditAddress(defaultBilling.id)"
                >{{ $t("edit_address") }}</b-link
              >
            </b-col>
            <b-col sm="6">
              <h3>{{ $t("default_shipping_address") }}</h3>
              <div v-if="defaultShipping == null">
                {{ $t("no_default_shipping_address") }}
              </div>
              <address v-if="defaultShipping != null">
                <span
                  >{{ defaultShipping.firstname }}
                  {{ defaultShipping.lastname }}</span
                >
                <span v-if="defaultShipping.company != null">{{
                  defaultShipping.company
                }}</span>
                <span>{{ defaultShipping.street[0] }}</span>
                <span
                  >{{ defaultShipping.city }},
                  {{ defaultShipping.postcode }}</span
                >
                <span>{{ defaultShipping.country_code }}</span>
                <span
                  >T:<a :href="`tel:` + defaultShipping.telephone">
                    {{ defaultShipping.telephone }}</a
                  ></span
                >
                <span v-if="defaultShipping.vat_id != null"
                  >VAT:{{ defaultShipping.vat_id }}</span
                >
              </address>
              <b-link
                class="link"
                @click="goToEditAddress(defaultShipping.id)"
                >{{ $t("edit_address") }}</b-link
              >
            </b-col>
          </b-row>
        </div>
        <div class="address-block">
          <h2>Adressen toevoegen</h2>
          <div class="address-table-wrap">
            <h3 v-if="addressList && addressList.length === 0">
              U heeft geen andere adressen toegevoegd aan uw adressenboek.
            </h3>
            <b-table
              class="address-table"
              responsive
              :items="addressList"
              :fields="fields"
            >
              <template v-slot:cell(btn)="data">
                <span class="adjust"
                  ><b-link @click="Adjust(data.item.id)">{{
                    data.value.label
                  }}</b-link></span
                >
                <span class="remove"
                  ><b-link @click="Remove(data.item.id)">{{
                    data.value.label2
                  }}</b-link></span
                >
              </template>
            </b-table>
            <div class="select-group d-flex justify-content-end">
              <span>Toon</span>
              <div class="select-wrap">
                <b-form-select
                  v-model="selectedOption"
                  :options="options"
                  class="select"
                ></b-form-select>
              </div>
              <span>per pagina</span>
            </div>
          </div>
        </div>
        <div class="form-footer">
          <b-button
            @click="newAddress()"
            type="button"
            variant="info"
            class="text-white form-submit-btn"
            >Adres toevoegen</b-button
          >
        </div>
      </div>
    </div>
    <AccountEditAddress v-if="editAddressId != 0" />
  </div>
</template>

<script>
import AccountEditAddress from "@/base/core/components/account/AccountEditAddress";

export default {
  name: "AccountAddressBook",
  components: { AccountEditAddress },
  data() {
    return {
      selectedOption: 10,
      options: [10, 20, 30],
      fields: [
        {
          key: "firstname",
          label: this.$t("form_first_name"),
        },
        {
          key: "lastname",
          label: this.$t("form_last_name"),
        },
        {
          key: "street[0]",
          label: this.$t("form_street"),
        },
        {
          key: "city",
          label: this.$t("form_city"),
        },
        {
          key: "country_code",
          label: this.$t("form_country"),
        },
        {
          key: "postcode",
          label: this.$t("form_postal_code"),
        },
        {
          key: "telephone",
          label: this.$t("form_phone"),
        },
        {
          key: "btn",
          label: "",
        },
      ],
    };
  },
  computed: {
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    editAddressId: {
      get() {
        return this.$store.getters["user/getEditAddressId"];
      },
      set(val) {
        this.$store.commit("user/setEditAddressId", val);
      },
    },
    user() {
      return this.$store.getters["user/getCurrentUser"];
    },
    defaultBilling() {
      return this.$store.getters["user/getDefaultBillingAddress"];
    },
    defaultShipping() {
      return this.$store.getters["user/getDefaultShippingAddress"];
    },
    addressList() {
      if (typeof this.user.addresses != "undefined") {
        const addresses = this.user.addresses;
        const addr = [];
        addresses.forEach((element) => {
          const btn = {
            label: this.$t("edit"),
            label2: this.$t("delete"),
          };
          element.btn = btn;
          addr.push(element);
        });
        return addr;
      }
      return [];
    },
  },

  methods: {
    newAddress() {
      this.editAddressId = -1;
    },
    Adjust(id) {
      this.editAddressId = id;
    },
    Remove(id) {
      this.$store.dispatch("user/deleteAddress", { id: id });
    },
    goToEditAddress(id) {
      this.tabIndex = 3;
      this.$store.commit("user/setEditAddressId", id);
    },
    goToEditPassword() {
      this.tabIndex = 4;
    },
  },
};
</script>


